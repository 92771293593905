<template>
  <PageTemplate>
		<Section>
			<Heading size="2">Latest News from the Industry</Heading>
			<Grid>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="3">
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="340px" image="theme-wm/blog1.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
							</Stack>
							<Heading size="5">The fastest way to grow your business with us</Heading>
						</Link>
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="380px" image="theme-wm/blog8.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
							</Stack>
							<Heading size="5">Digitize your business now with our best experts</Heading>
						</Link>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="3">
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="275px" image="theme-wm/blog2.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 13, 2021</Heading>
							</Stack>
							<Heading size="5">Hiring the best consultants to help you grow business</Heading>
						</Link>
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="340px" image="theme-wm/blog7.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
							</Stack>
							<Heading size="5">Finding cleaner ways to power the world today</Heading>
						</Link>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="3">
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="340px" image="theme-wm/blog3.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 8, 2021</Heading>
							</Stack>
							<Heading size="5">Process of creating a strong, positive perception</Heading>
						</Link>
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="380px" image="theme-wm/blog4.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
							</Stack>
							<Heading size="5">The fastest way to grow your business with us</Heading>
						</Link>
					</Stack>
				</GridContainer>
				<GridContainer size="12 6@sm 3@md">
					<Stack direction="column" align="left" space="3">
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="200px" image="theme-wm/blog5.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 1, 2021</Heading>
							</Stack>
							<Heading size="5">Process of giving a meaning to organization</Heading>
						</Link>
						<Link url="/windermere/post">
							<Stack direction="column" align="left" space="2">
								<Images width="100%" height="380px" image="theme-wm/blog6.jpg" />
								<Heading size="6" uppercase>CONSULTING / JULY 27, 2021</Heading>
							</Stack>
							<Heading size="5">The long-term goals that can be achieved</Heading>
						</Link>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
